import { Avatar } from "antd-mobile";

const typedate=[
    [
        {
            title:"干饭",
            key:1,
            icon:<Avatar src={'../img/iconimg/canyin.png'} style={{ '--size': '50px' }}/>,
            selectcolor:"#ffff00"
        },
        {
            title:"购物",
            key:2,
            icon:<Avatar src={'../img/iconimg/gouwu.png'} style={{ '--size': '50px' }}/>,
            selectcolor:"#ffff00"
        },
        {
            title:"日用品",
            key:3,
            icon:<Avatar src={'../img/iconimg/riyongpin.png'} style={{ '--size': '50px' }}/>,
            selectcolor:"#ffff00"
        },
        {
            title:"蔬菜",
            key:4,
            icon:<Avatar src={'../img/iconimg/shucai.png'} style={{ '--size': '50px' }}/>,
            selectcolor:"#ffff00"
        }
    ],
    [
        {
            title:"水费",
            key:5,
            icon:<Avatar src={'../img/iconimg/shuifei.png'} style={{ '--size': '50px' }}/>,
            selectcolor:"#ffff00"
        },
        {
            title:"电费",
            key:6,
            icon:<Avatar src={'../img/iconimg/dianbiao.png'} style={{ '--size': '50px' }}/>,
            selectcolor:"#ffff00"
        },
        {
            title:"燃气费",
            key:7,
            icon:<Avatar src={'../img/iconimg/ranqi.png'} style={{ '--size': '50px' }}/>,
            selectcolor:"#ffff00"
        },
        {
            title:"交通",
            key:8,
            icon:<Avatar src={'../img/iconimg/gongjiao.png'} style={{ '--size': '50px' }}/>,
            selectcolor:"#ffff00"
        }
    ],
    [
        {
            title:"水果",
            key:9,
            icon:<Avatar src={'../img/iconimg/shuiguo.png'} style={{ '--size': '50px' }}/>,
            selectcolor:"#ffff00"
        },
        {
            title:"零食",
            key:10,
            icon:<Avatar src={'../img/iconimg/lingshi.png'} style={{ '--size': '50px' }}/>,
            selectcolor:"#ffff00"
        },
        {
            title:"运动",
            key:11,
            icon:<Avatar src={'../img/iconimg/yundonghuwaileimu.png'} style={{ '--size': '50px' }}/>,
            selectcolor:"#ffff00"
        },
        {
            title:"娱乐",
            key:12,
            icon:<Avatar src={'../img/iconimg/yule.png'} style={{ '--size': '50px' }}/>,
            selectcolor:"#ffff00"
        }
    ],
    [
        {
            title:"服饰",
            key:13,
            icon:<Avatar src={'../img/iconimg/yundongfushi.png'} style={{ '--size': '50px' }}/>,
            selectcolor:"#ffff00"
        },
        {
            title:"美容",
            key:14,
            icon:<Avatar src={'../img/iconimg/meirong.png'} style={{ '--size': '50px' }}/>,
            selectcolor:"#ffff00"
        },
        {
            title:"住房",
            key:15,
            icon:<Avatar src={'../img/iconimg/zhufang.png'} style={{ '--size': '50px' }}/>,
            selectcolor:"#ffff00"
        },
        {
            title:"居家",
            key:16,
            icon:<Avatar src={'../img/iconimg/jujia.png'} style={{ '--size': '50px' }}/>,
            selectcolor:"#ffff00"
        }
    ],
    [
        {
            title:"幼儿",
            key:17,
            icon:<Avatar src={'../img/iconimg/shouye.png'} style={{ '--size': '50px' }}/>,
            selectcolor:"#ffff00"
        },
        {
            title:"长辈",
            key:18,
            icon:<Avatar src={'../img/iconimg/changbei.png'} style={{ '--size': '50px' }}/>,
            selectcolor:"#ffff00"
        },
        {
            title:"做饭",
            key:19,
            icon:<Avatar src={'../img/iconimg/shouyecaipumaicai.png'} style={{ '--size': '50px' }}/>,
            selectcolor:"#ffff00"
        },
        {
            title:"烟酒",
            key:20,
            icon:<Avatar src={'../img/iconimg/yanjiu.png'} style={{ '--size': '50px' }}/>,
            selectcolor:"#ffff00"
        }
    ],
    [
        {
            title:"数码",
            key:21,
            icon:<Avatar src={'../img/iconimg/weibiaoti2fuzhi03.png'} style={{ '--size': '50px' }}/>,
            selectcolor:"#ffff00"
        },
        {
            title:"汽车",
            key:22,
            icon:<Avatar src={'../img/iconimg/icon.png'} style={{ '--size': '50px' }}/>,
            selectcolor:"#ffff00"
        },
        {
            title:"医疗",
            key:23,
            icon:<Avatar src={'../img/iconimg/mazuike--.png'} style={{ '--size': '50px' }}/>,
            selectcolor:"#ffff00"
        },
        {
            title:"书籍",
            key:24,
            icon:<Avatar src={'../img/iconimg/shuji.png'} style={{ '--size': '50px' }}/>,
            selectcolor:"#ffff00"
        }
    ],
    [
        {
            title:"学习",
            key:25,
            icon:<Avatar src={'../img/iconimg/xuexi.png'} style={{ '--size': '50px' }}/>,
            selectcolor:"#ffff00"
        },
        {
            title:"宠物",
            key:26,
            icon:<Avatar src={'../img/iconimg/xiedaichongwu.png'} style={{ '--size': '50px' }}/>,
            selectcolor:"#ffff00"
        },
        {
            title:"礼金",
            key:27,
            icon:<Avatar src={'../img/iconimg/lijin.png'} style={{ '--size': '50px' }}/>,
            selectcolor:"#ffff00"
        },
        {
            title:"礼物",
            key:28,
            icon:<Avatar src={'../img/iconimg/baobei.png'} style={{ '--size': '50px' }}/>,
            selectcolor:"#ffff00"
        }
    ],
    [
        {
            title:"办公",
            key:29,
            icon:<Avatar src={'../img/iconimg/bangong.png'} style={{ '--size': '50px' }}/>,
            selectcolor:"#ffff00"
        },
        {
            title:"维修",
            key:30,
            icon:<Avatar src={'../img/iconimg/weixiu.png'} style={{ '--size': '50px' }}/>,
            selectcolor:"#ffff00"
        },
        {
            title:"捐赠",
            key:31,
            icon:<Avatar src={'../img/iconimg/aixinjuanzeng.png'} style={{ '--size': '50px' }}/>,
            selectcolor:"#ffff00"
        },
        {
            title:"彩票",
            key:32,
            icon:<Avatar src={'../img/iconimg/caipiao.png'} style={{ '--size': '50px' }}/>,
            selectcolor:"#ffff00"
        }
    ],
    [
        {
            title:"快递",
            key:33,
            icon:<Avatar src={'../img/iconimg/kuaidi.png'} style={{ '--size': '50px' }}/>,
            selectcolor:"#ffff00"
        },
        {
            title:"社交",
            key:34,
            icon:<Avatar src={'../img/iconimg/shejiao.png'} style={{ '--size': '50px' }}/>,
            selectcolor:"#ffff00"
        },
        {
            title:"旅行",
            key:35,
            icon:<Avatar src={'../img/iconimg/lvhang.png'} style={{ '--size': '50px' }}/>,
            selectcolor:"#ffff00"
        },
        {
            title:"其他类型",
            key:36,
            icon:<Avatar src={'../img/iconimg/other.png'} style={{ '--size': '50px' }}/>,
            selectcolor:"#ffff00"
        }
    ],

]
const gettypebykey=(key)=>{
    for(let x in typedate){
        for(let y in typedate[x]){
            if(typedate[x][y].key===key)return typedate[x][y]
        }
    }
}
export  {typedate,gettypebykey};