import React from 'react';
import {
    NavBar,
    Collapse,
    List,
    DotLoading,
    Space,
    Button,
    TextArea,
    Toast,
  } from 'antd-mobile'

// import { sleep } from 'antd-mobile/es/utils/sleep';
import copy from 'copy-to-clipboard';
import { request } from '../util/helper';
import baseurl from '../util/baseurl';

class DynamicContent extends React.Component{
    constructor(props){
        super()
        this.state={
            finished:false,
            quickurl:"",
        }
    }
    componentDidMount(){
        const loadData = async () => {
            await request({
                url:baseurl.tally.getquickurlbyname,
                method:'post',
                props:this.props.myprops,
                data:{
                    "username":this.props.username,
                    "tallyid":this.props.selfid
                },
                loadingflag:true,
              }).then((res)=>{
                if(res&&res.msg){
                    this.setState({
                        quickurl:window.location.protocol+'//'+window.location.host+'/quickurl/'+res.msg
                    }) 
                }
                this.setState({
                    finished:true
                })
              })
            
          }
        loadData()
    }
    render(){
        const resetquickurl=()=>{
            const loadData = async () => {
                await request({
                    url:baseurl.tally.setquickurlbyname,
                    method:'post',
                    props:this.props.myprops,
                    data:{
                        "username":this.props.username,
                        "tallyid":this.props.selfid
                    },
                  }).then((res)=>{
                    if(res&&res.msg){
                        this.setState({
                            quickurl:window.location.protocol+'//'+window.location.host+'/quickurl/'+res.msg
                        }) 
                    }
                  })
                
              }
            loadData()
        }
        return this.state.finished ? (
            <div>
                <TextArea
                placeholder='您还未生成快速访问链接'
                rows={3}
                disabled={true}
                value={this.state.quickurl}
                />
                <Space justify='center' block style={{ '--gap': '40px' }}>
                    <Button onClick={resetquickurl}>{!this.state.quickurl? "生成":"重置"}链接</Button>
                    <Button onClick={()=>{
                        copy(this.state.quickurl);
                        Toast.show({
                            content: '链接已复制',
                            })
                        }}
                        disabled={!this.state.quickurl}
                    >复制链接</Button>
                </Space>
            </div>
          ) : (
            <DotLoading />
          )
    }
}
class Quickurlmanage extends React.Component{
    constructor(props){
        super()
        this.state={
        }
        this.backclick=this.backclick.bind(this);
    }
    componentDidMount(){
        console.log(this.props.location.state)
    }
    backclick(){
        this.props.history.push("/tallybook/list")
    }
    render(){
        const otheruserlist=JSON.parse(this.props.location.state.otheruserlist);
        return(
            <div style={{"backgroundColor":"#f2f2f2",minHeight:"100vh"}}>
                <NavBar onBack={()=>this.backclick()}>
                    关系快捷链接管理
                </NavBar>
                <List mode='card'>
                    <Collapse >
                        <Collapse.Panel key='1' title={this.props.location.state.username}>
                            <DynamicContent myprops={this.props} 
                            username={this.props.location.state.username}
                            selfid={this.props.location.state.id}/>
                        </Collapse.Panel>
                        {otheruserlist.map((item)=>{
                            return <Collapse.Panel key={item} title={item}>
                                        <DynamicContent myprops={this.props} 
                                        username={item}
                                        selfid={this.props.location.state.id}/>
                                    </Collapse.Panel>
                        })}
                    </Collapse>
                    
                </List>
            </div>
        );
    }
}
export default Quickurlmanage;