// import React from 'react';
// import {
//     Form,
//     Input,
//     Button,
//   } from 'antd-mobile';
// import { request } from '../public/util/helper';
// import baseurl from '../public/util/baseurl';
// class Login extends React.Component{
//     constructor(props){
//         super();
//         this.state={
//             username:"",
//             password:"",
//             dengluloading:false,
//           }
//           this.submit=this.submit.bind(this);
//     }
//     submit(){
//         console.log(this.props)
//         this.setState({
//             dengluloading:true
//         });
//         request({
//             url:baseurl.login.loginon,
//             method:'post',
//             data:{
//               "username":this.state.username,
//               "password":this.state.password,
//             },
//             props:this.props,
//           }).then((res)=>{
//             if(!res.token){
//                 this.setState({
//                     dengluloading:false
//                 });
//             }
//             // this.props.history.replace({pathname:"/tallybook/list"});
//           });
//     }
//     render(){
//         return(
//             <div style={{"marginTop":"120px"}}>
//                 <div style={{"width":"200px","margin":"0 auto",textAlign:"center"}}>
//                     <img src='./LOGOTallyBook.png' width="120px" height="130px" alt="LOGO" />
//                     <p><span style={{fontSize: 12}}></span><b><font size="5" face="楷体"><i>TallyBook</i></font></b><span style={{fontSize: 12}}></span></p>
//                 </div>
//                 <Form
//                     layout='horizontal'
//                     footer={
//                         <Button block type='submit' color='primary' size='large' loading={this.state.dengluloading}>
//                         登录
//                         </Button>
//                     }
//                     onFinish={()=>this.submit()}
//                     >
//                     <Form.Header>TallyBook登录</Form.Header>
//                     <Form.Item
//                         name='username'
//                         label='用户名'
//                         rules={[{ required: true, message: '用户名不能为空' }]}
//                     > 
//                         <Input onChange={(value)=>{this.setState({"username":value})}} placeholder='请输入用户名' />
//                     </Form.Item>
//                     <Form.Item
//                         name='password'
//                         label='密码'
//                         rules={[{ required: true, message: '密码不能为空' }]}
//                     >
//                         <Input onChange={(value)=>{this.setState({"password":value})}} type="password" placeholder='请输入密码' />
//                     </Form.Item>
//                 </Form>
//             </div>
//         );
//     }
// }
// export default Login;

import {  localStorageSet } from '../public/util/localStorageutil';
import React from 'react';
import { request } from '../public/util/helper';
import baseurl from '../public/util/baseurl';

function getParam(name, defaultValue){
  var href=window.location.href;
	var query = href.substring(href.indexOf("?")+1,href.length);
	var vars = query.split("&");
	for (var i=0;i<vars.length;i++) {
		var pair = vars[i].split("=");
		if(pair[0] === name){return pair[1] + (pair[2] ? '=' + pair[2] : '');}
	}
	return(defaultValue === undefined ? null : defaultValue);
}

class Login extends React.Component{
  constructor(props){
      super();
      this.state={
        back: getParam("back", window.location.protocol+'//'+window.location.host+'/'),
        ticket: getParam("ticket",'')
      }
      this.doLoginByTicket=this.doLoginByTicket.bind(this);
      this.goSsoAuthUrl=this.goSsoAuthUrl.bind(this);
    }
    componentDidMount(){
      if(this.state.ticket) {
        this.doLoginByTicket(this.state.ticket);
      } else {
        this.goSsoAuthUrl();
      }
      console.log('获取 back 参数：', this.state.back)
      console.log('获取 ticket 参数：', this.state.ticket)
    }
    doLoginByTicket(ticket){
      request({
        url:baseurl.sso.checkTicket,
        method:'post',
        data:{
          ticket:ticket
        },
        props:this.props,
      }).then(res=>{
        if(res&&res.token){
            // localStorage.setItem('tallybooktoken',res.token);
            localStorageSet("tallybooktoken",res.token);
          }
      })
    }
    goSsoAuthUrl(){
      request({
        url:baseurl.sso.getSsoAuthUrl,
        method:'post',
        props:this.props,
      }).then(res=>{
        let authurl=res.authurl+"?redirect="+window.location.href;
        window.location.href = decodeURIComponent(authurl);
      })
    }
    render(){
      return <div></div>
    }
}

export default Login;