import localStorage from "localStorage";
//设置缓存
const localStorageSet = (name, data) => {
    // const obj = {
    //     data,
    //     expire: new Date().getTime() + 1000 * 60 * 60 *25
    // };
    localStorage.setItem(name, JSON.stringify(data));
};

//读取缓存
const localStorageGet = name => {
    const storage = localStorage.getItem(name);
    // const time = new Date().getTime();
    let result = null;
    if (storage) {
        const obj = JSON.parse(storage);
        result = obj
        // if (time < obj.expire) {
        //     result = obj.data;
        // } else {
        //     localStorage.removeItem(name);
        // }
    }
    return result;
};

const localStorageRemove = name=>{
    return localStorage.removeItem(name);
}
 const localStorageclear=()=>{
     return localStorage.clear();
 }

export {localStorageGet,localStorageSet,localStorageRemove,localStorageclear}