import React from "react";
import { List ,SwipeAction,PullToRefresh,Dialog} from 'antd-mobile'
import { sleep } from 'antd-mobile/es/utils/sleep'
import { BillOutline } from 'antd-mobile-icons'
import { localStorageRemove, localStorageGet, localStorageSet } from '../util/localStorageutil';
import { request } from '../util/helper';
import baseurl from '../util/baseurl';
class TallybookList extends React.Component{
    constructor(props){
        super();
        this.state={
            listconment:<List><List.Item >账单列表加载中。。。</List.Item></List>,
            tallylist:localStorageGet("tallylist")? localStorageGet("tallylist"):[],
        }
        this.initlistconment=this.initlistconment.bind(this);
        this.gettallylist=this.gettallylist.bind(this);
    }
    componentDidMount(){
        this.gettallylist()
    }
    gettallylist(){
        if(!localStorageGet("tallylist")){
            request({
              url:baseurl.tally.gettallylist,
              method:'post',
              props:this.props.mainprops,
              loadingflag:true,
            }).then((res)=>{
              // console.log(res);
              if(res&&res.data){
                localStorageSet("tallylist",res.data)
                this.setState({
                  tallylist:res.data,
                });
                this.setState({
                    listconment:this.initlistconment()
                });
              }
            })
          }else{
            this.setState({
                listconment:this.initlistconment()
            });
          }
        
    }
    initlistconment(){
        const getlable=(value)=>{
            const options=[
                {
                  label: '家庭账本',
                  value: '1',
                },
                {
                  label: '日常账本',
                  value: '2',
                },
                {
                  label: '多人账本',
                  value: '3',
                },
                {
                    label: '私有账本',
                    value: '4',
                  },
              ]
              let res=""
              for(let i in options){
                  if(options[i].value===value)
                  {
                      res=options[i].label
                  }
              }
              return res
        }
        const leftActions= (item)=>{
            return [
                {
                  key: 'tallyuser',
                  text: '用户',
                  color: 'primary',
                  onClick:(a,key=item)=>{
                      this.props.mainprops.history.replace({pathname:'/quickurlmanage',state:key});
                  }
                },
                {
                  key: 'delete',
                  text: '删除',
                  color: 'danger',
                  onClick:(a,key=item)=>{
                    Dialog.confirm({
                        content:'确定要删除账本'+key.tallyname+'吗？',
                        onConfirm: async () => {
                            request({
                                url:baseurl.tally.deletetallylistbyid,
                                method:'post',
                                props:this.props,
                                data:key
                              }).then((res)=>{
                                localStorageRemove("tallylist");
                                this.gettallylist()
                              })
                        },
                      })
                  }
                },
              ]
        }
        return (
            <PullToRefresh
                onRefresh={async () => {
                    localStorageRemove("tallylist");
                    this.gettallylist()
                    await sleep(1000)
                }}
                >
                <List  header='账本' style={{"minHeight":"70vh"}}>
                    {this.state.tallylist.map(item => (
                    <SwipeAction
                        key={item.id}
                        leftActions={leftActions(item)}
                    >
                        <List.Item description={'类型:'+getlable(item.tallytype)} clickable
                        onClick={(a,key=item)=>{
                            this.props.mainprops.history.replace({pathname:'/tallbookdetails',state:key});
                        }}
                        prefix={<BillOutline />}
                        >{item.tallyname}</List.Item>
                    </SwipeAction>
                    ))}
                </List>
          </PullToRefresh>
        )
      }
    
    render(){
        return(
            <div style={{"backgroundColor":"#f2f2f2",minHeight:"90vh"}}>{this.state.listconment}</div>
        );
    }
}
export default TallybookList;