import React from 'react';
import {
    NavBar,
    Space,
    Popup,
    Input,
    NumberKeyboard,
    TextArea,
    Form,
    DatePicker,
    Dialog,
  } from 'antd-mobile'
//   import {
//     FingerdownOutline,
//   } from 'antd-mobile-icons';
import Iconselect from './iconselect';
import baseurl from '../util/baseurl';
import { request } from '../util/helper';
import { typedate } from '../util/typedate';

class QuickAddtallydetail extends React.Component{
    constructor(props){
        super();
        this.state={
            selectedkey:null,
            Popupvisible:false,
            money:"",
            bz:"",
            PickerVisible:false,
            NumberKeyboardvisible:false,
            date:this.datef(new Date(),"yyyy-MM-dd"),

        }
        this.backclick=this.backclick.bind(this);
        this.selectedkeychange=this.selectedkeychange.bind(this);
        this.datef=this.datef.bind(this);
    }
    UNSAFE_componentWillMount(){
        let token=this.props.match.params.key;
        if(token){
            const loadData = async () => {
            await request({
                url:baseurl.quickurl.quertallyinfobytoken,
                method:'post',
                props:this.props,
                data:{
                    "token":token
                },
                })
            
            }
        loadData()
        }
    }
    backclick(){
        this.props.history.replace({pathname:'/quicktallbookdetails/'+this.props.match.params.key});
    }
    datef(date,fmt){
        var o = {
            "M+": date.getMonth() + 1, //月份 
            "d+": date.getDate(), //日 
            "h+": date.getHours(), //小时 
            "m+": date.getMinutes(), //分 
            "s+": date.getSeconds(), //秒 
            "q+": Math.floor((date.getMonth() + 3) / 3), //季度 
            "S": date.getMilliseconds() //毫秒 
        };
        if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
        for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        return fmt;
    }
    selectedkeychange(value){
        this.setState(
            {
                selectedkey:value
            }
        )
        if(value){
            this.setState(
                {
                    Popupvisible:true,
                    NumberKeyboardvisible:true
                }
            )
        }
    }
    render(){
        
        const onInput = (value) => {
            if(value==='.' && this.state.money.indexOf(".") !== -1)return
            let str =this.state.money.split('.');
            if(str.length>=2 && str[1].length>=2)return
            this.setState({
                money:this.state.money+value
            })
          }
        
        const onDelete = () => {
        this.setState({
            money:this.state.money.slice(0, this.state.money.length - 1)
        })
        }
        const now=new Date();
        
        return(
            <div style={{minHeight:"100vh"}}>
                <div className='topNavBar' style={{position:"fixed",top:0,width:"100%",zIndex:999,opacity:1.0,backgroundColor:"#ffffff"}}>
                <NavBar onBack={()=>this.backclick()}
                >
                  记一笔
                </NavBar>
                </div>
                <div style={{"height":"45px"}}></div>
                {
                    typedate.map((items,index)=>{
                        return <Space wrap block style={{'--gap': '30px'}} justify='center' direction='horizontal' key={index}>
                                    {
                                        items.map((item,index)=>{
                                            return <Iconselect key={index}
                                            onClick={this.selectedkeychange} 
                                            value={this.state.selectedkey}
                                            prams={item}
                                            />
                                        })
                                    }
                                </Space>
                    })
                }
                {this.state.Popupvisible? <div style={{"height":"433px"}}></div>:null}
                <Popup
                    visible={this.state.Popupvisible}
                    // mask={false}
                    onMaskClick={() => {
                        this.setState({
                            // selectedkey:null,
                            Popupvisible:false,
                            NumberKeyboardvisible:false,
                        })
                    }}
                    bodyStyle={{
                        borderTopLeftRadius: '8px',
                        borderTopRightRadius: '8px',
                        minHeight: '433px',
                    }}
                    >
                    <Form
                        layout='horizontal'
                        >
                        <Form.Item
                            label='金额'
                        >
                            <Input value={this.state.money}  placeholder='请输入金额' readOnly />
                        </Form.Item>
                        <Form.Item
                            label='时间'
                            trigger='onConfirm'
                            onClick={() => {
                                this.setState({
                                    PickerVisible:true,
                                    NumberKeyboardvisible:false
                                })
                            }}
                            >
                            <DatePicker
                                visible={this.state.PickerVisible}
                                onClose={() => {
                                    this.setState({
                                        PickerVisible:false,
                                        NumberKeyboardvisible:true,
                                    })
                                }}
                                defaultValue={now}
                                max={now}
                                onConfirm={val => {
                                    this.setState({
                                        date:this.datef(val,"yyyy-MM-dd")
                                    })
                                }}
                            >
                                {value => value?  this.datef(value,"yyyy-MM-dd"):"请选择日期"}
                            </DatePicker>
                        </Form.Item>
                        <Form.Item  label='备注' help='对特别账单备注'>
                            <TextArea
                            placeholder='请输入备注'
                            maxLength={100}
                            rows={2}
                            showCount
                            value={this.state.bz} onChange={(value)=>{this.setState({bz:value})}}
                            />
                        </Form.Item>
                    </Form>
                </Popup>
                <NumberKeyboard
                    style={{"zIndex":700}}
                    visible={this.state.NumberKeyboardvisible}
                    onInput={onInput}
                    onDelete={onDelete}
                    customKey='.'
                    confirmText='确定'
                    onConfirm={()=>{
                        Dialog.confirm({
                            content:'确定要新建一条记录吗?',
                            onConfirm: async () => {
                                request({
                                    url:baseurl.quickurl.addtallydetail,
                                    method:'post',
                                    props:this.props,
                                    data:{
                                        "token":this.props.match.params.key,
                                        "type":this.state.selectedkey,
                                        "time":this.state.date,
                                        "money":this.state.money,
                                        "bz":this.state.bz
                                    }
                                  })
                                  .then((res)=>{
                                    if(res){
                                        this.backclick()
                                    }
                                  })
                            },
                          })
                        console.log(this.state)
                    }}
                />  
            </div>
        );
    }
}
export default QuickAddtallydetail;