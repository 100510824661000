import React from 'react';
import { 
    List, 
    Switch ,
    Collapse,
    Input,
    Button,
    FloatingPanel,
    Toast,
    SwipeAction,
    Dialog,
    Avatar,
    NavBar
} from 'antd-mobile';
import { request } from '../util/helper';
import baseurl from '../util/baseurl';
import { localStorageGet,localStorageSet,localStorageRemove } from '../util/localStorageutil';

 class Userinfo extends React.Component{
  constructor(props){
      super();
      this.state={
        newpushflag:false,
        FloatingPanelshowflag:false,
        FloatingPanelconment:null,
        userinfo:localStorageGet("userinfo")? localStorageGet("userinfo"):{
            username:"加载中。。",
            newpushurl:null,
            userimg:"",
        },
        reactives:localStorageGet("reactives")? localStorageGet("reactives"):[]
      }
      this.showuserguanxi=this.showuserguanxi.bind(this);
      this.updateFlaotingpanelconment=this.updateFlaotingpanelconment.bind(this);
  }
  componentDidMount(){
      if(!localStorageGet("userinfo")||!localStorageGet("reactives")){
        request({
            url:baseurl.user.getuserinfo,
            method:'post',
            props:this.props.mainprops,
          }).then((res)=>{
              if(res.userinfo){
                this.setState({userinfo:res.userinfo});
                localStorageSet("userinfo",res.userinfo)
                if(res.userinfo.newpushflag==="N")this.setState({newpushflag:false});
                else this.setState({newpushflag:true});
              }
              if(res.reactives){
                localStorageSet("reactives",res.reactives)
                this.setState({reactives:res.reactives});
              }
              
          })
      }
  }
  showuserguanxi(){
      let data=this.state.reactives;
      const leftActions= (item,index)=>{
        return [
            {
                key: 'delete',
                text: '删除',
                color: 'danger',
              onClick:(a,key=item,inde=index)=>{
                Dialog.confirm({
                    content: '确定要删除关系'+key.username+'吗？',
                    onConfirm: async () => {
                        request({
                            url:baseurl.user.deleteuserguanxibyid,
                            method:'post',
                            props:this.props.mainprops,
                            data:{"id":key.id}
                          }).then((res)=>{
                            delete data[inde];
                            localStorageRemove("reactives")
                            this.updateFlaotingpanelconment(data,leftActions)
                              Toast.show({
                                icon: 'success',
                                content: '删除成功',
                                position: 'bottom',
                              })
                          })
                    },
                  })
              }
            },
          ]
    }
    this.setState({
        FloatingPanelshowflag:!this.state.FloatingPanelshowflag,
    })
    this.updateFlaotingpanelconment(data,leftActions)
  }
  updateFlaotingpanelconment(data,leftActions){
      debugger
    this.setState({
        FloatingPanelconment:<List>
                                {data.map((item, index) => (
                                    <SwipeAction
                                    key={item.id}
                                    leftActions={leftActions(item,index)}
                                    >
                                        <List.Item description={'关系：'+item.reactiveflag+'，添加时间:'+item.creattime}
                                        prefix={<Avatar src={'../img/userimg/'+item.userimg+'.png'} />}
                                        >{item.username}
                                        </List.Item>
                                    </SwipeAction>
                                ))}
                                <div style={{"height":"45px"}}></div>
                            </List>
    });
  }
  render(){    
    const anchors = [260, window.innerHeight * 0.4, window.innerHeight * 0.8]
      return(
          <div style={{"backgroundColor":"#f2f2f2",minHeight:"90vh"}}>
            <div style={{"width":"100%","height":"25vh",textAlign:"center",backgroundColor:"#7bbfec"}}>
               {this.state.userinfo.userimg? <img src={'../img/userimg/'+this.state.userinfo.userimg+'.png'} width="70px" height="70px" alt="userimg" style={{"marginTop":40}}/>:null} 
                <p><span style={{fontSize: 10}}></span><b><font size="5" face="楷体"><i>{this.state.userinfo.username}</i></font></b><span style={{fontSize: 12}}></span></p>
            </div>
            <List mode='card' header='新消息管理'>
                <List.Item extra={<Switch checked={this.state.newpushflag}
                                        onChange={checked => {
                                            this.setState({newpushflag:checked})
                                        }} />}>新消息通知</List.Item>
                <Collapse >
                    <Collapse.Panel key='1' title='消息推送url' disabled={!this.state.newpushflag}>
                    {this.state.newpushflag? <List.Item
                        label='url'
                        extra={
                        <div style={{"borderLeft":"solid 1px #eeeeee","paddingLeft":"12PX"}}>
                            <Button color='primary' fill='none'>
                                确定
                            </Button>
                        </div>
                        }
                    >
                        <Input placeholder='url' clearable />
                    </List.Item>:null}
                    </Collapse.Panel>
                </Collapse>
            </List>
            <List mode='card' header='关系管理'>
            <List.Item description='查看和管理用户关系，以添加账单使用人' 
            onClick={
                ()=>{this.showuserguanxi()}
                } clickable>
            管理关系
            </List.Item>
            <List.Item description='添加用户关系，以添加账单使用人'
            onClick={()=>{
                this.props.mainprops.history.replace({pathname:"/adduserguanxi"});
            }}
            clickable>
            添加新关系
            </List.Item>
            </List>


            {this.state.FloatingPanelshowflag?<FloatingPanel anchors={anchors}>
                <List><List.Item>
                    <NavBar onBack={()=>{this.setState(
                    {FloatingPanelshowflag:!this.state.FloatingPanelshowflag}
                    )}}>管理关系</NavBar>
                </List.Item></List>
                {this.state.FloatingPanelconment}
            </FloatingPanel>:null}
        </div>
      );
  }
 }
 
 export default Userinfo;