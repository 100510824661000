import React from 'react';
// import {
//     Form,
//   } from 'antd-mobile'
class Iconselect extends React.Component{
    constructor(props){
        super();
    }
        
    render(){
        const color=this.props.value===this.props.prams.key? this.props.prams.selectcolor?this.props.prams.selectcolor:"#1677ff":null;
        if(this.props.prams&&this.props.prams.icon){
            return(
                <div  style={{"display":"inline-block"}}
                onClick={
                    ()=>{
                        if(this.props.value===this.props.prams.key){
                            this.props.onClick(null)
                        }else this.props.onClick(this.props.prams.key)
                }}>
                    <div style={{backgroundColor:color, borderRadius: "45px",width:65,height:65,}}>
                        <div style={{height:50,paddingLeft:7,paddingTop:7} }>
                            {this.props.prams.icon}
                        </div>
                    </div>
                    <div style={{fontSize: 18,width:"100%",textAlign:"center"}}>{this.props.prams.title}</div>
                </div>
            );
        }else return null
        
    }
}
export default Iconselect;