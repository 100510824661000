const baseurl={
    "login":{
        "loginon":"/TallyBook/login/loginon/",
        "loginout":"/TallyBook/login/loginout/",
        "updatepwd":"/TallyBook/login/updatepwd/",
        // "kaptcha":"/TallyBook/login/defaultKaptcha/"
    },
    "sso":{
        "getSsoAuthUrl":"/TallyBook/sso/getSsoAuthUrl/",
        "checkTicket":"/TallyBook/sso/checkTicket/"
    },
    "user":{
        "getuserinfo":"/TallyBook/user/getuserinfo/",
        "adduserguanxi":"/TallyBook/user/adduserguanxi/",
        "deleteuserguanxibyid":"/TallyBook/user/deleteuserguanxibyid/"
    },
    "tally":{
        "addtallylist":"/TallyBook/tally/addtallylist/",
        "gettallylist":"/TallyBook/tally/gettallylist/",
        "deletetallylistbyid":"/TallyBook/tally/deletetallylistbyid/",
        "addtallydetail":"/TallyBook/tally/addtallydetail/",
        "quertallydetailsall":"/TallyBook/tally/quertallydetailsall/",
        "deletetallydetailsbyid":"/TallyBook/tally/deletetallydetailsbyid/",
        "getquickurlbyname":"/TallyBook/tally/getquickurlbyname/",
        "setquickurlbyname":"/TallyBook/tally/setquickurlbyname/",
    },
    "quickurl":{
        "addtallydetail":"/TallyBook/quickurl/addtallydetail/",
        "quertallydetailsall":"/TallyBook/quickurl/quertallydetailsall/",
        "quertallyinfobytoken":"/TallyBook/quickurl/quertallyinfobytoken/",
        "deletetallydetailsbyid":"/TallyBook/quickurl/deletetallydetailsbyid/",
    }
}

export default baseurl;