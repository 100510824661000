import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import App from './App';
import Login from './login/login';
import Test from './public/test/test';
import reportWebVitals from './reportWebVitals';
// import { HashRouter, Route } from 'react-router-dom';
import TallbookMain from './public/model/tallybookmain';
import Addtally from './public/model/addtally';
import Notfound from './public/model/notfound';
import Tallbookdetails from './public/model/Tallybookdetails';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
import Adduserguanxi from './public/model/adduserguabxi';
import Addtallydetail from './public/model/addtallydetail';
import Quickurlmanage from './public/model/quickurlmanage';
import QuickAddtallydetail from './public/model/quickurladdtallydetail';
import QuickTallbookdetails from './public/model/quicktallbookdetails';
ReactDOM.render(
  <Router>
        <Switch>
          <Route exact path="/" component={TallbookMain}/>
          <Route exact path="/login" component={Login}/>
          <Route path="/tallybook/:key" component={TallbookMain}/>
          <Route path="/tallybook" component={TallbookMain}/>
          <Route path="/addtally" component={Addtally}/>
          <Route path="/test" component={Test}/>
          <Route path="/adduserguanxi" component={Adduserguanxi}/>
          <Route path="/tallbookdetails" component={Tallbookdetails}/>
          <Route path="/addtallydetail" component={Addtallydetail}/>
          <Route path="/quickurlmanage" component={Quickurlmanage}/>
          <Route path="/quickurl/:key" component={QuickAddtallydetail}/>
          <Route path="/quicktallbookdetails/:key" component={QuickTallbookdetails}/>
          <Route exact path="/Notfound" component={Notfound}/>
          <Route path="*" component={Notfound}/>
        </Switch>
    </Router>,
document.getElementById("root"))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
