import React from 'react';
import {
    Empty,
  } from 'antd-mobile'
class Notfound extends React.Component{
    render(){
        return(
            <Empty
          style={{ padding: '64px 0' }}
          imageStyle={{ width: 128 }}
          description='404 页面丢失了！'
        />
        );
    }
}
export default Notfound;