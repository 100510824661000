import React from 'react';
import {
    NavBar,
    ActionSheet,
    Space,
    Dropdown,
    CheckList,
    Toast,
    List,
    DatePicker,
    Collapse,
    SwipeAction,
    Dialog,
    PullToRefresh,
  } from 'antd-mobile'
import {
  MoreOutline,
} from 'antd-mobile-icons';
import { sleep } from 'antd-mobile/es/utils/sleep'
import { gettypebykey } from '../util/typedate';

import { request } from '../util/helper';
import baseurl from '../util/baseurl';
class QuickTallbookdetails extends React.Component{
    constructor(props){
        super();
        this.state={
            ActionSheetvisible:false,
            component:null,
            groupby:"groupbyD",
            startdate:"",
            enddate:"",
            startPickerVisible:false,
            endPickerVisible:false,
            selectpeople:"",
            tallyinfo:{
              id:"",
              username:"",
              otheruserlist:"[]",
              tallyname:"",
            },
            userinfo:{},
        }
        this.backclick=this.backclick.bind(this);
        this.datef=this.datef.bind(this);
        this.initcomponent=this.initcomponent.bind(this);
    }
    componentDidMount(){
      let token=this.props.match.params.key;
      if(token){
        const loadData = async () => {
          await request({
              url:baseurl.quickurl.quertallyinfobytoken,
              method:'post',
              props:this.props,
              data:{
                  "token":token
              },
            }).then((res)=>{
              console.log(res)
              if(res){
                  this.setState({
                    tallyinfo:res.tallyinf,
                    userinfo:res.userinfo,
                  })
                  this.initcomponent();
              }
            })
          
        }
      loadData()
      }
      // console.log(this.props.location.data);
     
    }
    initcomponent(startdate,enddate,groupby,selectpeople){
      request({
        url:baseurl.quickurl.quertallydetailsall,
        method:'post',
        props:this.props,
        data:{
          // "token":this.props.match.params.key,
          "tallyid":this.state.tallyinfo.id,
          "groupby":groupby?groupby:this.state.groupby,
          "startdate":startdate? startdate:this.state.startdate,
          "enddate":enddate? enddate:this.state.enddate,
          "selectpeople":selectpeople||selectpeople===''? selectpeople:this.state.selectpeople,
        }
      }).then((res)=>{
        if(res){
          const leftActions= (item)=>{
            if(item.username===this.state.userinfo.username){
              return [
                {
                    key: 'delete',
                    text: '删除',
                    color: 'danger',
                  onClick:(a,key=item)=>{
                    Dialog.confirm({
                        content: '确定要删除吗？',
                        onConfirm: async () => {
                          request({
                            url:baseurl.quickurl.deletetallydetailsbyid,
                            method:'post',
                            props:this.props,
                            data:{
                              "id":item.id
                            }
                          }).then((res)=>{
                            this.initcomponent();
                          })
                        },
                      })
                  }
                },
              ]
            }else{
              return [
                {
                    key: 'delete',
                    text: '删除',
                    color: 'light',
                  onClick:(a,key=item)=>{
                    Toast.show({
                      icon: 'fail',
                      content: '你无法删除他人的账单',
                    })
                  }
                },
              ]
            }
            
        }
          const resx= res.map((items,index)=>{
            return <List mode='card' header={items.headname} key={index}>
                      <Collapse defaultActiveKey={['Collapse']}>
                        <Collapse.Panel key={'Collapse'} title={<div>共：{items.sumnum}<label style={{"float":"right"}}>-{items.summoney}</label></div>}>
                        <List>
                          {items.deatilslists.map((item,index2)=>{
                            let typedata=gettypebykey(parseInt(item.type))
                            return <SwipeAction
                                    key={index2}
                                    leftActions={leftActions(item)}
                                    >
                                        <List.Item description={item.time+' '+item.username+' '+item.bz}
                                        prefix={typedata.icon}
                                        >{<div style={{"color":"#000000"}}>{typedata.title}<label style={{"float":"right"}}>-{item.money}</label></div>}
                                        </List.Item>
                                    </SwipeAction>
                          })}
                        </List>
                        </Collapse.Panel>
                      </Collapse>
                    </List>
          })
          
          this.setState({
            component:
                    <PullToRefresh 
                    onRefresh={async () => {
                      this.initcomponent();
                        await sleep(1000)
                    }}
                    >
                      {resx}
                    </PullToRefresh>
          })
        }
      })
      
    }
    datef(date,fmt){
      var o = {
          "M+": date.getMonth() + 1, //月份 
          "d+": date.getDate(), //日 
          "h+": date.getHours(), //小时 
          "m+": date.getMinutes(), //分 
          "s+": date.getSeconds(), //秒 
          "q+": Math.floor((date.getMonth() + 3) / 3), //季度 
          "S": date.getMilliseconds() //毫秒 
      };
      if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
      for (var k in o)
      if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
      return fmt;
  }
    backclick(){
      Dialog.confirm({
        content: '即将前往登录首页',
        onConfirm: ()=>{this.props.history.push("/login")}
      })
        
    }
    render(){
      
        const actions=(history)=>{

            return [
              {
                text: '记一笔',
                key: 'addtallydetail',
                onClick:(h=history) => {
                  h.replace({pathname:'/quickurl/'+this.props.match.params.key});
                  // history.push('/addtally');
                },
              },
            ]
          }
        const right = ()=>{
              return <div style={{ fontSize: 24 }}>
                        <Space style={{ '--gap': '16px' }}>
                          <MoreOutline  onClick={() =>this.setState({ActionSheetvisible:true})}/>
                        </Space>
                      </div>
        }
        const now=new Date();
        return(
            <div style={{"backgroundColor":"#f2f2f2",minHeight:"100vh"}}>
                <NavBar onBack={()=>this.backclick()}
                right={right()}
                >
                    {this.state.tallyinfo.tallyname}
                </NavBar>
                <Dropdown>
                  <Dropdown.Item key='sorter' title='分组'>
                    <CheckList 
                    defaultValue={[this.state.groupby]}
                    onChange={
                      (value)=>{
                        if(value[0]){
                          this.setState({
                            groupby:value[0]
                          })
                          this.initcomponent(null,null,value[0]);
                        }else {
                          Toast.show({
                            icon: 'fail',
                            content: '请选择一个分组',
                          })
                        }
                      }}
                    >
                      <CheckList.Item value='groupbyD'>按日分组</CheckList.Item>
                      <CheckList.Item value='groupbyM'>按月分组</CheckList.Item>
                      <CheckList.Item value='groupbyY'>按年分组</CheckList.Item>
                    </CheckList>
                  </Dropdown.Item>
                  <Dropdown.Item key='people' title='人员'>
                    <CheckList 
                    defaultValue={[this.state.selectpeople]}
                    onChange={
                      (value)=>{
                        if(value[0]||value[0]===''){
                          this.setState({
                            selectpeople:value[0]
                          })
                          this.initcomponent(null,null,null,value[0]);
                        }else {
                          Toast.show({
                            icon: 'fail',
                            content: '请选择一个分组',
                          })
                        }
                      }}
                    >
                      <CheckList.Item value=''>全部</CheckList.Item>
                      <CheckList.Item value={this.state.tallyinfo.username}>
                        {this.state.tallyinfo.username}</CheckList.Item>
                      {JSON.parse(this.state.tallyinfo.otheruserlist).map((item)=>{
                        return <CheckList.Item key={item} value={item}>{item}</CheckList.Item>
                      })}
                    </CheckList>
                  </Dropdown.Item>
                  <Dropdown.Item key='bizop' title='筛选'>
                    <List>
                      <List.Item extra={
                        <DatePicker
                          visible={this.state.startPickerVisible}
                          onClose={() => {
                              this.setState({
                                  startPickerVisible:false,
                              })
                          }}
                          // defaultValue={now}
                          max={now}
                          onConfirm={val => {
                              this.setState({
                                  startdate:this.datef(val,"yyyy-MM-dd")
                              })
                              this.initcomponent(this.datef(val,"yyyy-MM-dd"),null);
                          }}
                      >
                          {value => value?  this.datef(value,"yyyy-MM-dd"):"未选择"}
                      </DatePicker>
                      } onClick={() => {this.setState({ startPickerVisible:true})}}>
                        起始时间
                      </List.Item>
                      <List.Item extra={
                        <DatePicker
                            visible={this.state.endPickerVisible}
                            onClose={() => {
                                this.setState({
                                    endPickerVisible:false,
                                })
                            }}
                            // defaultValue={now}
                            max={now}
                            onConfirm={val => {
                                this.setState({
                                    enddate:this.datef(val,"yyyy-MM-dd")
                                })
                                this.initcomponent(null,this.datef(val,"yyyy-MM-dd"));
                            }}
                        >
                            {value => value?  this.datef(value,"yyyy-MM-dd"):"未选择"}
                        </DatePicker>
                      } onClick={() => {this.setState({ endPickerVisible:true})}}>
                        终止时间
                      </List.Item>
                    </List>
                  </Dropdown.Item>
                </Dropdown>
                {this.state.component}
                <ActionSheet
                    cancelText='取消'
                    visible={this.state.ActionSheetvisible}
                    actions={actions(this.props.history)}
                    onClose={() => this.setState({ActionSheetvisible:false})}
                    />
                
            </div>
        );
    }
}
export default QuickTallbookdetails;