import React from 'react';
import {
    NavBar,
    Dialog,
    Form,
    Button,
    Avatar,
    CapsuleTabs,
    Input,
    List,
    Popup,
    CheckList,
    Toast,
  } from 'antd-mobile'
import baseurl from '../util/baseurl';
import { request } from '../util/helper';
import { localStorageRemove } from '../util/localStorageutil';
class Adduserguanxi extends React.Component{
    constructor(props){
        super();
        this.state={
            userimgname:'Gululu(1)',
            Popupvisible:false,
            Popupconment:null,
            guanxitype:[],
        }
        this.backclick=this.backclick.bind(this);
    }
    componentDidMount(){
        this.setState({
            Popupconment:<CheckList onChange={(value)=>{this.setState({guanxitype:value,Popupvisible:false})}}>
                            <CheckList.Item value='家人'>家人</CheckList.Item>
                            <CheckList.Item value='亲戚'>亲戚</CheckList.Item>
                            <CheckList.Item value='朋友'>朋友</CheckList.Item>
                            <CheckList.Item value='室友'>室友</CheckList.Item>
                            <CheckList.Item value='其他'>其他</CheckList.Item>
                        </CheckList>
        })
    }
    backclick(){
        this.props.history.push("/tallybook/me")
    }
    render(){
        const imgdata=[
            'Gululu(1)',
            'Gululu(2)',
            'Gululu(3)',
            'Gululu(4)',
            'Gululu(5)',
            'Gululu(6)',
            'Gululu(7)',
            'Gululu(9)',
            'Gululu(10)',
            'Gululu(11)',
            'Gululu(12)',
            'Gululu(13)',
            'girl-1',
            'girl-2',
            'girl-4',
            'girl-5',
            'girl-6',
            'boy-1',
            'boy-8',
            'boy-6',
            'boy-7',
            'boy-5',
            'boy-4',
            'boy-3',
            'img(1)',
            'img(2)',
            'img(3)',
            'img(4)',
            'img(5)',
            'img(6)',
            'img(7)',
            'img(8)',
            'img(9)',
            'img(10)',
            
        ]
        const onFinish = (values) => {
            values.userimg=this.state.userimgname;
            values.type=this.state.guanxitype;
            if(values.type.length<1){
                Toast.show({
                    icon: 'fail',
                    content: '请选择关系类别',
                    position: 'bottom',
                  })
                  return
            }
            Dialog.confirm({
                content: "确定要新增关系"+values.name+"吗？",
                onConfirm: async () => {
                    request({
                        url:baseurl.user.adduserguanxi,
                        method:'post',
                        props:this.props,
                        data:values
                      }).then((res)=>{
                        localStorageRemove("reactives")
                        //   debugger
                      })
                },
              })
          }
        return(
            <div style={{"backgroundColor":"#f2f2f2",minHeight:"100vh"}}>
                <NavBar onBack={()=>this.backclick()}>
                    添加新关系
                </NavBar>
                <Form 
                    layout='horizontal' 
                    mode='card'
                    onFinish={onFinish}
                    footer={
                        <Button block type='submit' color='primary' size='large'>
                            提交
                        </Button>
                    }>
                        <Form.Header >人员头像</Form.Header>
                        <Form.Item name='userimgname' label='头像'>
                            <Avatar src={'../img/userimg/'+this.state.userimgname+'.png'} style={{ '--size': '64px' }} />
                        </Form.Item>
                        <CapsuleTabs onChange={(key)=>{this.setState({"userimgname":key})}}>
                                {imgdata.map((item)=>{
                                    return(<CapsuleTabs.Tab title={item} key={item}>
                                                {/* <Avatar src={'../img/userimg/'+item+'.png'} style={{ '--size': '64px' }} /> */}
                                            </CapsuleTabs.Tab> )
                                    
                                })}
                        </CapsuleTabs>
                        <Form.Header >人员信息</Form.Header>
                        <Form.Item name='name' label='名称' rules={[{ required: true }]}>
                            <Input placeholder='请输入人员名称' />
                        </Form.Item>
                        <List.Item extra={this.state.guanxitype.length>0?this.state.guanxitype:"请选择"} onClick={()=>{this.setState({Popupvisible:true})}} clickable>
                            关系类别标签
                        </List.Item>
                </Form>
                <Popup
                    visible={this.state.Popupvisible}
                    onMaskClick={() => {
                        this.setState({Popupvisible:false})
                    }}
                    bodyStyle={{ height: '50vh' }}
                    >
                    {this.state.Popupconment}
                </Popup>
            </div>
        );
    }
}
export default Adduserguanxi;