import React from 'react';
import {
    Form,
    Input,
    Button,
    NavBar,
    Dialog,
    List,
    Selector,
    FloatingPanel,
    CheckList,
    Avatar,
  } from 'antd-mobile'
  import {
    UserAddOutline,
  } from 'antd-mobile-icons';
//   import { sleep } from 'antd-mobile/es/utils/sleep';
//   import cookie from 'react-cookies';
import { request } from '../util/helper';
// import Item from 'antd-mobile/es/components/dropdown/item';
import baseurl from '../util/baseurl';
import { localStorageGet, localStorageRemove, localStorageSet } from '../util/localStorageutil';
class Addtally extends React.Component{
    constructor(props){
        super()
        this.state={
            Popupvisible:false,
            Popupconment:null,
            CheckListvalue:[],
            
        }
        this.backclick=this.backclick.bind(this);
        this.addtallyusershowlist=this.addtallyusershowlist.bind(this);
    }
    backclick(){
        this.props.history.push("/tallybook/list")
    }
    addtallyusershowlist(){
        debugger
        let reactives=localStorageGet("reactives");
        if(!reactives){
            request({
                url:baseurl.user.getuserinfo,
                method:'post',
                props:this.props,
              }).then((res)=>{
                  if(res.reactives){
                    localStorageSet("reactives",res.reactives)
                    reactives=res.reactives
                    this.setState({
                        Popupconment: <div>
                                        <CheckList multiple defaultValue={this.state.CheckListvalue}
                                        onChange={(value)=>{this.setState({CheckListvalue:value})}}>
                                            {reactives.map((item)=>{
                                                return <CheckList.Item 
                                                description={'关系：'+item.reactiveflag+'，添加时间:'+item.creattime}
                                                prefix={<Avatar src={'../img/userimg/'+item.userimg+'.png'} /> }
                                                value={item.username}
                                                key={item.id}
                                                 >{item.username}</CheckList.Item>
                                            })}
                                        </CheckList>
                                    </div>
                    })
                  }
                  
              })
        }else{
            this.setState({
                Popupconment: <div>
                                <CheckList multiple defaultValue={this.state.CheckListvalue}
                                onChange={(value)=>{this.setState({CheckListvalue:value})}}>
                                    {reactives.map((item)=>{
                                        return <CheckList.Item 
                                        description={'关系：'+item.reactiveflag+'，添加时间:'+item.creattime}
                                        prefix={<Avatar src={'../img/userimg/'+item.userimg+'.png'} /> }
                                        value={item.username}
                                        key={item.id}
                                         >{item.username}</CheckList.Item>
                                    })}
                                </CheckList>
                            </div>
            })
        }
        
    }
    render(){
        const onFinish = (values) => {
            values.peoper=this.state.CheckListvalue;
            Dialog.confirm({
                content:'确定要新建账本'+values.name+'吗？',
                onConfirm: async () => {
                    request({
                        url:baseurl.tally.addtallylist,
                        method:'post',
                        props:this.props,
                        data:values
                      }).then((res)=>{
                        localStorageRemove("tallylist")
                      })
                },
              })
          }
        const options=[
            {
              label: '家庭账本',
              value: '1',
            },
            {
              label: '日常账本',
              value: '2',
            },
            {
              label: '多人账本',
              value: '3',
            },
            {
                label: '私有账本',
                value: '4',
              },
          ]
          const anchors = [260, window.innerHeight * 0.4, window.innerHeight * 0.8]
        return(
            <div style={{"backgroundColor":"#f2f2f2",minHeight:"100vh"}}>
                <NavBar onBack={()=>this.backclick()}>
                    新建账本
                </NavBar>
                <Form 
                    layout='horizontal' 
                    mode='card'
                    onFinish={onFinish}
                    footer={
                        <Button block type='submit' color='primary' size='large'>
                            提交
                        </Button>
                    }>
                    <Form.Header>账本信息</Form.Header>
                    <Form.Item name='name' label='名称' rules={[{ required: true }]}>
                        <Input placeholder='请输入账本名称' />
                    </Form.Item>
                    <Form.Item name='type' label='类型' rules={[{ required: true }]}>
                    <Selector
                    options={options}
                    // onChange={(arr, extend) => console.log(arr, extend.items)}
                    />
                    </Form.Item>
                    <Form.Header >人员信息</Form.Header>
                    <List.Item prefix={<UserAddOutline />} 
                    extra={this.state.CheckListvalue.length>0?JSON.stringify(this.state.CheckListvalue):"请选择"} 
                    onClick={() => {this.setState({Popupvisible:true});this.addtallyusershowlist()}}>
                    选择其他成员使用
                    </List.Item>
                </Form>
                {this.state.Popupvisible?<FloatingPanel anchors={anchors}>
                    <List><List.Item>
                        <NavBar onBack={()=>{this.setState(
                        {Popupvisible:!this.state.Popupvisible}
                        )}}>选择人员</NavBar>
                    </List.Item></List>
                    {this.state.Popupconment}
                </FloatingPanel>:null}
            </div>
        );
    }
}
export default Addtally;