import React from 'react';
// import { Skeleton } from 'antd-mobile'
import {  TabBar ,NavBar,Space,ActionSheet ,Dialog} from 'antd-mobile';
import { request } from '../util/helper';
import baseurl from '../util/baseurl';
import {
    UnorderedListOutline,
    UserOutline,
    MoreOutline,
  } from 'antd-mobile-icons';
  import {
    Route,
    Switch,
    useHistory,
    useLocation,
    MemoryRouter as Router,
  } from 'react-router-dom';
import Userinfo from './userinfo';
import TallybookList from './tallybooklist';
import { localStorageclear, localStorageGet } from '../util/localStorageutil';
class TallbookMain extends React.Component{
  constructor(props){
    super();
    this.state={
      NavBartitile:"账本列表",
      NavBarKey:"/list",
      listnum:1,
      newmessagenum:1,
      ActionSheetvisible:false,
      myhistory:null,
    }
  }
  componentDidMount(){
    if(!localStorageGet("tallybooktoken")){
      this.props.history.push("/login")
    }
    if(this.props.match.params.key==="me"){
      this.setState({NavBartitile:"个人中心"})
    }
  }

    render(){
      const Bottom = (props) => {
        const history = useHistory()
        const location = useLocation()
        const { pathname } = location
      
        const tabs = [
          {
            key: '/list',
            title: '账本列表',
            icon: <UnorderedListOutline />,
          },
          {
            key: '/me',
            title: '个人中心',
            icon: <UserOutline />,
          },
        ]
        const setRouteActive = (value) => {
          history.push(value)
          tabs.map(item=>{
            if(item.key===value){
              this.setState({
                NavBartitile:item.title,
                NavBarKey:item.key
            })
            }
            return null;
          });
        }
        return (
          <TabBar activeKey={pathname} onChange={value => setRouteActive(value)}>
            {tabs.map(item => (
              <TabBar.Item
              key={item.key}
              icon={item.icon}
              title={item.title}
              badge={item.badge}
              />
            ))}
          </TabBar>
        )
      }
        const back = () =>{
          Dialog.confirm({
            content: '确定要退出登录吗？',
            onConfirm: async () => {
              request({
                url:baseurl.login.loginout,
                method:'post',
                props:this.props,
              }).then(()=>{
                localStorageclear();
              })
            },
          })
        }
        const Navbarlistclick = () =>this.setState({ActionSheetvisible:true})
        const right = (NavBarKey)=>{
          if(NavBarKey==="/list"){
            return <div style={{ fontSize: 24 }}>
                      <Space style={{ '--gap': '16px' }}>
                        <MoreOutline  onClick={Navbarlistclick}/>
                      </Space>
                    </div>
          }
          return null;
        }
        const actions=(history)=>{

          return [
            {
              text: '新建账本',
              key: 'addtallylist',
              onClick:(h=history) => {
                h.replace('/addtally');
                // history.push('/addtally');
              },
            },
          ]
        }
        return(
          <Router initialEntries={["/"+this.props.match.params.key]}>
            <div className='topNavBar' style={{position:"fixed",top:0,width:"100%",zIndex:999,opacity:1.0,backgroundColor:"#ffffff"}}>
                <NavBar right={right(this.state.NavBarKey)} onBack={back}>
                    {this.state.NavBartitile}
                </NavBar>
            </div>
            <div style={{"height":"45px"}}></div>
            <div>
              <Switch>
                <Route exact path='/list'>
                  <TallybookList mainprops={this.props}/>
                </Route>
                <Route exact path='/me'>
                  <Userinfo mainprops={this.props}/>
                </Route>
                <Route exact path="*">
                  <TallybookList mainprops={this.props}/>
                </Route>
              </Switch>
            </div>
            <div style={{"height":70}}></div>
            <div 
            className='bottomTabBar' 
            style={{
              position:"fixed",
              bottom:0,
              width:"100%",
              zIndex:500,
              opacity:1.0,
              backgroundColor:"#ffffff"}}>
              <Bottom />
            </div>
            <ActionSheet
              cancelText='取消'
              visible={this.state.ActionSheetvisible}
              actions={actions(this.props.history)}
              onClose={() => this.setState({ActionSheetvisible:false})}
            />
        </Router>
        );
    }
}
export default TallbookMain;