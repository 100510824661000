import { Toast } from 'antd-mobile'
import { localStorageGet, localStorageSet } from './localStorageutil';

let props 

function parseJSON(response){
    return response.json()
  }
  
  function checkStatus(response){
    if(response.status === 200){
      return response
    }
    Toast.show({
      icon: 'fail',
      content: response.status+" "+response.statusText,
      maskClickable: false,
      duration: 1000
    });
    const error = new Error(response.statusText)
    error.response = response
    throw error
  }
  /**
   * 请求
   * @param {*url,*method,*data} options 
   */
  export  function request(options = {}){
    const Authorization = localStorageGet('tallybooktoken');
    const {data,url,loadingflag} = options
    options = {...options}
    delete options.url;
    props=options.props;
    delete options.props;
    if(data){
      delete options.data
      options.body = JSON.stringify(data)
    }
    if(!loadingflag){
      Toast.show({
        icon: 'loading',
        content: '加载中。。。',
        maskClickable: false,
        duration: 30000
      });
    }
    options.headers={
      'tallybooktoken':Authorization,
      'Content-Type':'application/json;charset=UTF-8'
    }
    return fetch(url,options,{credentials: 'include'})
      .then(checkStatus)
      .then(parseJSON)
      .then((res)=>{
        // debugger
        if(res.state ===200){
          Toast.clear();
          return res.dateObject
        }
        else if(res.state === 500){
          Toast.show({
            icon: 'fail',
            content: res.msg,
            maskClickable: false,
            duration: 2000
          });
          // message.error({ content: res.msg, key, duration: 2 });
          return ;
        }
        else if(res.state === 302){
            if(res.dateObject&&res.dateObject.token){
                // localStorage.setItem('tallybooktoken',res.token);
                localStorageSet("tallybooktoken",res.dateObject.token);
            }
            if(res.msg){
              props.history.replace({pathname:res.url,data:res.dateObject});
              Toast.show({
                content: res.msg,
                maskClickable: false,
                duration: 1000,
              });
            }else{
              Toast.clear();
              props.history.replace({pathname:res.url,data:res.dateObject});
            }
          return res.dateObject;
        }else{
          return ;
        }
        
      })
      .catch(err=>({err}))
  }